angular.module('fingerink')
    .service('buyPlanModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function (productId) {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/buyPlanModal.tpl.html',
                controller: 'buyPlanModalCtrl',
                controllerAs: 'controller',
                animation: false,
                size: 'lg',
                keyboard: true,
                resolve: {
                    invoice: orderService => orderService.buyProductPresupuesto(productId),
                    productId: () => productId
                }
            });

            return this.modalInstance;
        };

    }).controller('buyPlanModalCtrl', function ($scope, session, invoice, $uibModalInstance, orderService, productId, fingerinkSession) {

        var that = this;
        that.invoice = invoice.data;
        that.close = () => $uibModalInstance.close();

        that.buy = () => {
            that.loading = true;
            orderService.buyProduct(productId).then(response => {
                $uibModalInstance.close();
                fingerinkSession.renoveToken();
                swal("Correcto", "Has comprado el producto correctamente", "success");
            }, error => {
                fingerinkSession.renoveToken();
                swal("Error", "Ha fallado la compra del producto", "error");
                that.loading = false;
            });

        };
        that.change = () => {
            that.loading = true;
            orderService.changeNextMonthOrder(productId).then(response => {
                $uibModalInstance.close();
                fingerinkSession.renoveToken();
                swal("Correcto", "Has cambiado el plan correctamente", "success");
            }, error => {
                fingerinkSession.renoveToken();
                swal("Error", "Ha fallado el cambio del plan", "error");
                that.loading = false;
            });

        };
    });

